var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "EditLayout",
        {
          attrs: {
            item: _vm.item,
            "submit-button-text": _vm.isNew ? _vm.$t("create") : _vm.$t("save"),
            photo: _vm.photo,
            "supports-photo": "",
          },
          on: {
            submit: function ($event) {
              _vm.isNew ? _vm.create() : _vm.edit()
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "shadowTitle",
              attrs: { slot: "title" },
              slot: "title",
            },
            [_vm._v(" " + _vm._s(_vm.title) + " ")]
          ),
          _c("div", { attrs: { slot: "middle" }, slot: "middle" }, [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("BaseInput", {
                  staticClass: "col-12",
                  attrs: {
                    label: _vm.$tc("quote"),
                    placeholder: _vm.$t("enterAnAwesomeQuote"),
                  },
                  model: {
                    value: _vm.item.quote,
                    callback: function ($$v) {
                      _vm.$set(_vm.item, "quote", $$v)
                    },
                    expression: "item.quote",
                  },
                }),
                _c("BaseInput", {
                  staticClass: "col-md-6",
                  attrs: {
                    label: _vm.$tc("website"),
                    placeholder: _vm.$t("enterItemUrl", {
                      item: _vm.$t("personalWebsite"),
                    }),
                  },
                  model: {
                    value: _vm.item.website,
                    callback: function ($$v) {
                      _vm.$set(_vm.item, "website", $$v)
                    },
                    expression: "item.website",
                  },
                }),
                _c("BaseInput", {
                  staticClass: "col-md-6",
                  attrs: {
                    label: "Twitter",
                    placeholder: _vm.$t("enterItemUrl", { item: "Twitter" }),
                  },
                  model: {
                    value: _vm.item.twitter,
                    callback: function ($$v) {
                      _vm.$set(_vm.item, "twitter", $$v)
                    },
                    expression: "item.twitter",
                  },
                }),
                _c("BaseInput", {
                  staticClass: "col-md-6",
                  attrs: {
                    label: _vm.$t("facebookPage"),
                    placeholder: _vm.$t("enterItemUrl", {
                      item: _vm.$t("facebookPage"),
                    }),
                  },
                  model: {
                    value: _vm.item.facebook_page,
                    callback: function ($$v) {
                      _vm.$set(_vm.item, "facebook_page", $$v)
                    },
                    expression: "item.facebook_page",
                  },
                }),
                _c("BaseInput", {
                  staticClass: "col-md-6",
                  attrs: {
                    label: _vm.$t("facebookProfile"),
                    placeholder: _vm.$t("enterItemUrl", {
                      item: _vm.$t("facebookProfile"),
                    }),
                  },
                  model: {
                    value: _vm.item.facebook_profile,
                    callback: function ($$v) {
                      _vm.$set(_vm.item, "facebook_profile", $$v)
                    },
                    expression: "item.facebook_profile",
                  },
                }),
                _c("BaseInput", {
                  staticClass: "col-md-6",
                  attrs: {
                    label: "Instagram",
                    placeholder: _vm.$t("enterItemUrl", { item: "Instagram" }),
                  },
                  model: {
                    value: _vm.item.instagram,
                    callback: function ($$v) {
                      _vm.$set(_vm.item, "instagram", $$v)
                    },
                    expression: "item.instagram",
                  },
                }),
                _c("BaseInput", {
                  staticClass: "col-md-6",
                  attrs: {
                    label: "Pinterest",
                    placeholder: _vm.$t("enterItemUrl", { item: "Pinterest" }),
                  },
                  model: {
                    value: _vm.item.pinterest,
                    callback: function ($$v) {
                      _vm.$set(_vm.item, "pinterest", $$v)
                    },
                    expression: "item.pinterest",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("PermissionsCheckList", {
            attrs: {
              slot: "bottom",
              "active-permissions": _vm.userPermissions,
              "predefined-role": _vm.item.role_id,
            },
            on: { updatePermissions: _vm.updatePermissions },
            slot: "bottom",
          }),
          _c(
            "div",
            { attrs: { slot: "sidebar-middle" }, slot: "sidebar-middle" },
            [
              _c("div", [
                _c("label", [_vm._v(_vm._s(_vm.$tc("role")))]),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.item.role_id,
                        expression: "item.role_id",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      disabled:
                        !_vm.$store.getters[
                          "permissions/checkIfUserHasPermission"
                        ]("user.update"),
                    },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.item,
                          "role_id",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c(
                      "option",
                      {
                        attrs: { selected: "", disabled: "" },
                        domProps: { value: null },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("selectItem", { item: _vm.$tc("role") })
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _vm._l(_vm.roles, function (role) {
                      return _c(
                        "option",
                        {
                          key: "role" + role.id + role.name,
                          domProps: { value: role.id },
                        },
                        [_vm._v(" " + _vm._s(role.name) + " ")]
                      )
                    }),
                  ],
                  2
                ),
              ]),
              _c(
                "div",
                [
                  _c("BaseInput", {
                    attrs: {
                      label: "Email",
                      type: "email",
                      placeholder: _vm.$t("enterItem", { item: "Email" }),
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.item.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.item, "email", $$v)
                      },
                      expression: "item.email",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "BaseInput",
                    {
                      attrs: {
                        label: _vm.$t("showEmailToPublic"),
                        "is-slider-checkbox": "",
                      },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.show_email,
                            expression: "item.show_email",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "checkbox",
                          "true-value": "1",
                          "false-value": "0",
                        },
                        domProps: {
                          checked: Array.isArray(_vm.item.show_email)
                            ? _vm._i(_vm.item.show_email, null) > -1
                            : _vm._q(_vm.item.show_email, "1"),
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.item.show_email,
                              $$el = $event.target,
                              $$c = $$el.checked ? "1" : "0"
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.item,
                                    "show_email",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.item,
                                    "show_email",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.item, "show_email", $$c)
                            }
                          },
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("BaseInput", {
                    attrs: {
                      label: _vm.$t("firstName"),
                      placeholder: _vm.$t("enterItem", {
                        item: _vm.$t("firstName"),
                      }),
                    },
                    model: {
                      value: _vm.item.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.item, "name", $$v)
                      },
                      expression: "item.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("BaseInput", {
                    attrs: {
                      label: _vm.$t("lastName"),
                      placeholder: _vm.$t("enterItem", {
                        item: _vm.$t("lastName"),
                      }),
                    },
                    model: {
                      value: _vm.item.surname,
                      callback: function ($$v) {
                        _vm.$set(_vm.item, "surname", $$v)
                      },
                      expression: "item.surname",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("BaseInput", {
                    attrs: {
                      label: _vm.$t("username"),
                      placeholder: _vm.$t("enterItem", {
                        item: _vm.$t("username"),
                      }),
                    },
                    model: {
                      value: _vm.item.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.item, "username", $$v)
                      },
                      expression: "item.username",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("BaseInput", {
                    attrs: {
                      label: _vm.$t("nickname"),
                      placeholder: _vm.$t("enterItem", {
                        item: _vm.$t("nickname"),
                      }),
                    },
                    model: {
                      value: _vm.item.nickname,
                      callback: function ($$v) {
                        _vm.$set(_vm.item, "nickname", $$v)
                      },
                      expression: "item.nickname",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("BaseInput", {
                    attrs: {
                      label: _vm.$t("jobTitle"),
                      placeholder: _vm.$t("enterItem", {
                        item: _vm.$t("jobTitle"),
                      }),
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.item.job_title,
                      callback: function ($$v) {
                        _vm.$set(_vm.item, "job_title", $$v)
                      },
                      expression: "item.job_title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "password" },
                [
                  _c("label", [_vm._v("Password")]),
                  _c(
                    "div",
                    [
                      !_vm.isNew
                        ? _c(
                            "BaseButton",
                            {
                              attrs: {
                                size: "sm",
                                type: "green",
                                gradient: "",
                              },
                              on: { click: _vm.togglePassword },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("changePassword")) + " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.isNew || _vm.isPasswordOn
                    ? _c("BaseInput", {
                        attrs: {
                          placeholder: _vm.$t("enterItem", {
                            item: _vm.$t("password"),
                          }),
                          type: "password",
                          autocomplete: "new-password",
                          autofill: "off",
                        },
                        model: {
                          value: _vm.item.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.item, "password", $$v)
                          },
                          expression: "item.password",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }